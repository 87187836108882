


























import { Inject } from '@cds/common';
import moment from 'moment';
import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import VueUeditorWrap from "vue-ueditor-wrap";
import { NuoxiaoerService } from '@/services/nuoxiaoer-service';
import editorConfig from './KpiAssessment.config';

@Component({
  components: {
    VueUeditorWrap,
  }
})
export default class kpiAssessment extends Vue {
  private activeName = 'MR';
  private tabs = [
    { name: 'MR', label: 'MR' },
    { name: 'DM', label: 'DM' },
  ]
  private richText = '';
  public form = {}
  public tableData: any[] = [];
  public loading: Boolean = true;

  public editorConfig = editorConfig;

  @Inject(NuoxiaoerService) private nuoxiaoerService!: NuoxiaoerService;

  public async created(): Promise<void> {
    this.richText = `<table style="width: 100%;"><tbody><tr class="firstRow"><td valign="top" style="word-break: break-all;">文本</td><td valign="top" style="word-break: break-all;">文本</td><td valign="top" style="word-break: break-all;">文本</td><td valign="top" style="word-break: break-all;">文本</td><td valign="top" style="word-break: break-all;">文本</td></tr><tr><td valign="top" style="word-break: break-all;">文本</td><td valign="top" style="word-break: break-all;">文本</td><td valign="top" style="word-break: break-all;">文本</td><td valign="top" style="word-break: break-all;">文本</td><td valign="top" style="word-break: break-all;">文本</td></tr><tr><td valign="top" style="word-break: break-all;">文本</td><td valign="top" style="word-break: break-all;">文本</td><td valign="top" style="word-break: break-all;">文本</td><td valign="top" style="word-break: break-all;">文本</td><td valign="top" style="word-break: break-all;">文本</td></tr><tr><td valign="top" style="word-break: break-all;">文本</td><td valign="top" style="word-break: break-all;">文本</td><td valign="top" style="word-break: break-all;">文本</td><td valign="top" style="word-break: break-all;">文本</td><td valign="top" style="word-break: break-all;">文本</td></tr></tbody></table><p><br/></p>`;
  }

  private mounted() {
  }

  handleClickTab(tab: any, event: any) {
    console.log('点击tab', tab, event);
    const {active,label,name} = tab;
  }
}
