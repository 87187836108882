const editorConfig = {
  // https://github.com/HaoChuan9421/vue-ueditor-wrap/blob/master/public/UEditor/ueditor.config.js
  // 编辑器不自动被内容撑高
  autoHeightEnabled: false,
  // 初始容器高度
  initialFrameHeight: 500,
  // 初始容器宽度
  initialFrameWidth: "100%",
  // 上传文件接口（这个地址是我为了方便各位体验文件上传功能搭建的临时接口，请勿在生产环境使用！！！）
  //serverUrl: '/api/application/ueditor/config',
  serverUrl: '/wecom-buddy-backend/api/article/admin/richText/upload',
  catchRemoteImageEnable:true,
  // UEditor 资源文件的存放路径，如果你使用的是 vue-cli 生成的项目，通常不需要设置该选项，vue-ueditor-wrap 会自动处理常见的情况，如果需要特殊配置，参考下方的常见问题2
  UEDITOR_HOME_URL: "/ueditor/",
  toolbars: [
    [
      // 'fullscreen', // 右上角 全屏
      'source', // html源代码
      'undo', // 撤销
      'redo', // 重做
      'selectall', // 全选
      'cleardoc', // 清空文档
      // 'removeformat', // 清除格式
      // 'formatmatch', // 格式刷
    ],
    [
      'bold', // 加粗
      'italic', // 斜体
      'underline', // 下划线
      '|',
      // 'fontborder', // 字符边框
      // 'strikethrough', // 删除线
      'superscript', // 上标
      'subscript', // 下标
      // 'autotypeset', // 自动排版
      // 'blockquote', // 引用
      // 'pasteplain', // 纯文本粘贴模式
      '|',
      'forecolor', // 字体颜色
      'backcolor', // 背景色
      '|',
      'insertorderedlist', // 有序排列
      'insertunorderedlist', // 无序排列
      '|',
      // 'customstyle', // 自定义标题
      // 'paragraph', // 段落格式
      // 'fontfamily', // 字体
      'fontsize', // 字号
      'spechars', // 特殊字符
      '|',
      // 'directionalityltr', // 从左向右输入
      // 'directionalityrtl', // 从右向左输入
      // 'indent',
      '|',
      // 'rowspacingtop', // 段前距
      // 'rowspacingbottom', // 段后距
      // 'lineheight', // 行间距
      // '|',
      // 'justifyleft', // 居左对齐
      // 'justifycenter', // 居中对齐
      // 'justifyright', // 居右对齐
      // 'justifyjustify', // 两端对齐
      // '|',
      // 'touppercase', // 字母大写
      // 'tolowercase', // 字母小写
      // '|',
      // 'link', // 超链接
      // 'unlink', // 取消超链接
      // 'anchor', // 锚点
      // '|',
      // 'imagenone', // 默认
      // 'imageleft', // 左浮动
      // 'imageright', // 右浮动
      // 'imagecenter', // 居中浮动
      // 'simpleupload', // 图片上传
      // 'insertimage', // 多图上传
      // 'emotion', // 表情
      // 'scrawl', // 涂鸦
      // 'music', // 音乐
      // 'insertvideo', // 视频
      // 'insertaudio', // 音频
      // 'attachment', // 附件
      // 'map', // 百度地图
      // 'gmap', // 谷歌地图
      // 'insertframe', // 插入iframe
      // 'insertcode', // 代码语言
      // 'webapp', // 百度应用
      // 'pagebreak', // 分页
      // 'template', // 模板
      // 'background', // 背景
      '|',
      // 'horizontal', // 分割线
      // 'date', // 日期
      // 'time', // 时间
      // 'snapscreen', // 截图
      // 'wordimage', // 图片转存
      '|',
      // 'charts', // 图表
      '|',
      // 'print', // 打印
      'preview', // 预览
      // 'searchreplace', // 查询转换
      // 'drafts', // 从草稿箱加载
      // 'help', // 帮助
    ],
    [
      'inserttable', // 插入表格
      'deletetable', // 删除表格
      'insertparagraphbeforetable', // 表格前插入行
      'insertrow', // 前插入行
      'deleterow', // 删除行
      'insertcol', // 前插入列
      'deletecol', // 删除列
      'mergecells', // 合并多个单元格
      'mergeright', // 右合并单元格
      'mergedown', // 下合并单元格
      'splittocells', // 完全拆分单元格
      'splittorows', // 拆分成行
      'splittocols', // 拆分成列
    ]
  ],
  //当鼠标放在工具栏上时显示的tooltip提示,留空支持自动多语言配置，否则以配置值为准
  //,labelMap:{
  //    'anchor':'', 'undo':''
  //}
  'fontfamily': [
    { label: '', name: 'songti', val: '宋体,SimSun' },
    { label: '仿宋', name: 'fangsong', val: '仿宋,FangSong' },
    { label: '仿宋_GB2312', name: 'fangsong', val: '仿宋_GB2312,FangSong' },
    { label: '', name: 'kaiti', val: '楷体,楷体_GB2312, SimKai' },
    { label: '', name: 'yahei', val: '微软雅黑,Microsoft YaHei' },
    { label: '', name: 'heiti', val: '黑体, SimHei' },
    { label: '', name: 'lishu', val: '隶书, SimLi' },
    { label: '', name: 'andaleMono', val: 'andale mono' },
    { label: '', name: 'arial', val: 'arial, helvetica,sans-serif' },
    { label: '', name: 'arialBlack', val: 'arial black,avant garde' },
    { label: '', name: 'comicSansMs', val: 'comic sans ms' },
    { label: '', name: 'impact', val: 'impact,chicago' },
    { label: '', name: 'timesNewRoman', val: 'times new roman' }
  ],
  //如果自定义，最好给p标签如下的行高，要不输入中文时，会有跳动感
  initialStyle:'p{line-height:1em};', //编辑器层级的基数,可以用来改变字体等
  'customstyle':[
    {tag:'td', name:'tc', label:'', style:'border-bottom:#ccc 2px solid;padding:0 4px 0 0;text-align:center;margin:0 0 20px 0;'},
  //    {tag:'h1', name:'tc', label:'', style:'border-bottom:#ccc 2px solid;padding:0 4px 0 0;text-align:center;margin:0 0 20px 0;'},
  //    {tag:'h1', name:'tl',label:'', style:'border-bottom:#ccc 2px solid;padding:0 4px 0 0;margin:0 0 10px 0;'},
  //    {tag:'span',name:'im', label:'', style:'font-style:italic;font-weight:bold'},
  //    {tag:'span',name:'hi', label:'', style:'font-style:italic;font-weight:bold;color:rgb(51, 153, 204)'}
  ]

};


export default editorConfig;
